import React from "react";

import Layout from "../../../components/layout";
import {ApiDocSidebar} from "../../../components/sidebar";

const pageTitle = "Documentation";

const doxygenMarkerDiv = {
    __html: `
<div id="top">
    <!-- DOXYGEN HEADER END -->
</div>
<!-- DOXYGEN FOOTER START -->
`
};

const Page = () => (
    <Layout htmlTitle="$title"
            pageTitle={pageTitle}
            seoProps={{doxygen: true}}
            sidebarNav={<ApiDocSidebar/>}
            mobileNav={false}
    >
        <div className="doxygen" dangerouslySetInnerHTML={doxygenMarkerDiv}/>
    </Layout>
);

export default Page;
